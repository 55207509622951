import React, { FC, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import {
    applyProductChanges,
    resetProduct,
} from '../../../store/actions/configurationActions'
import { showModal } from '../../../store/actions/modalActions'
import { ConfigurationsActionType } from '../../../types/configurations'
import { ModalTypes } from '../../../types/modals'
import { getLabel } from '../../../validators'
import { RunTestsButton } from '../RunTestsButton'
import style from './Styles/index.module.scss'
import { IProduct } from '../../../types/product'
import ProductCardProductStatus from '../../ProductCard/components/ProductCardProductStatus'
import { getProductStatusValues } from '../../../utils/getProductStatusValues'
import { alertDetailedErrorMessage } from '../../../utils/Errors'

interface IConfigButtonsPanel {
    appName?: string
    title: string
    guid: string
    currentProduct: IProduct
}

const ConfigButtonsPanel: FC<IConfigButtonsPanel> = ({
  appName,
  title,
  guid,
  currentProduct
}) => {
  const dispatch = useDispatch()
  const { unsaved, productsConfigurations, valid } = useTypedSelector(
    (state) => state.configurations
  )
  const { productNameChangeCounter } = useTypedSelector(
    (state) => state.products
  )
  const validationKeys = Object.keys(valid[guid] || {})
  const [productName, setProductName] = useState<string>('')
  const { status, type } = currentProduct
  const { statusName, statusStyle } = getProductStatusValues(status, type)
  
  const getValidationResult = (): boolean => {
    for (let key in validationKeys) {
      if (Object.keys(valid[guid][validationKeys[key]]).length) {
        if (!valid[guid][validationKeys[key]].undefined) {
          return true
        }
      }
    }
    return false
  }

  const isProductValid: boolean = getValidationResult()

  const handleOnReset = (): void => {
    dispatch(
      showModal(ModalTypes.MAIN_MODAL, {
        body: `Reset all changes for ${appName}?`,
        onSubmit: () => dispatch(resetProduct(guid)),
      })
    )
  }

  const handleOnApply = (): void => {
    dispatch({
      type: ConfigurationsActionType.CHECK_INVALID_TABS,
      payload: { guid, data: 1 },
    })
    console.log(productsConfigurations[guid])
    if (isProductValid) {
      alertDetailedErrorMessage('Configuration was not applied. Contains error(s)')
    }
    if (!isProductValid) {
      dispatch(
        showModal(ModalTypes.MAIN_MODAL, {
          body: `Apply all changes for ${appName}?`,
          onSubmit: () =>
            dispatch(applyProductChanges(guid, productsConfigurations[guid])),
        })
      )
      dispatch({
        type: ConfigurationsActionType.CHECK_INVALID_TABS,
        payload: { guid, data: false },
      })
    }
  }

  useMemo(() => {
    const raw = localStorage.getItem(`${guid}-productName`) || getLabel(title)
    setProductName(raw)
  }, []) // eslint-disable-line

  useMemo(() => {
    const raw = localStorage.getItem(`${guid}-productName`) || getLabel(title)
    setProductName(raw)
  }, [productNameChangeCounter]) // eslint-disable-line

  return (
    <div className="d-flex mb-2 align-items-center">
      <div>
        <h1
          className={`${style.title} app-color-text mb-1 mt-2 text-capital word-break`}
        >
          {productName}
        </h1>
        <span className="text-secondary mb-1 d-block">App name: <b>{appName}</b></span>
        <span className="text-secondary mb-1 d-block">
            Status: <b>{statusName}</b> <span className={statusStyle} />
        </span>
      </div>
      <ProductCardProductStatus
          guid={guid}
          status={status}
          productType={type}
          hideStatus={true}
          className="ml-auto me-4"
      />
      <button
        type="button"
        onClick={handleOnReset}
        disabled={!unsaved[guid || '']}
        className="app-btn-reset btn-primary app-btn-main me-2"
      >
        <FormattedMessage id="reset" />
      </button>
      <button
        type="submit"
        onClick={handleOnApply}
        className="app-btn-main app-btn-apply"
      >
        <FormattedMessage id="apply" />
      </button>
      <RunTestsButton guid={guid} />
    </div>
  )
}

export default React.memo(ConfigButtonsPanel)
