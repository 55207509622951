import style from './LiveLogsBtn.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook'
import { withTooltip } from 't4b-core-frontend'

interface LiveLogsBtnProps {
    className?: string
}

export const LiveLogsBtn = (props: LiveLogsBtnProps) => {
    const { className } = props

    const liveLogsButton = (
        <button
            className={`${style.button} ${className}`}
            type="button"
        >
            <FontAwesomeIcon icon={faBook} />
        </button>
    )

    return (
        <div>
            {withTooltip(liveLogsButton, 'Live logs', 'liveLogs')}
        </div>
    )
}
