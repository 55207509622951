import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { Modal } from 'react-bootstrap'
import { hideModal } from '../../../store/actions/modalActions'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import style from './NotificationsModal.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import React, { ChangeEvent, useMemo, useState } from 'react'
import {
    fetchNotificationsOnScroll,
    markNotificationsRead,
} from '../../../store/actions/notificationsActions'
import { Notification } from './Notificaation/Notificaation'
import { FormattedMessage } from 'react-intl'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown'

export const NotificationsModal = () => {
    const dispatch = useAppDispatch()
    const { notifications } = useTypedSelector((store) => store.notifications)
    const [selected, setSelected] = useState<string[]>([])

    const unreadNotificationIds = useMemo(
        () =>
            notifications.reduce((acc: string[], notis) => {
                if (notis.status === 'unread') {
                    acc = [...acc, notis.notification_id]
                }
                return acc
            }, []),
        [notifications]
    )

    const handleClose = (): void => {
        dispatch(hideModal())
    }

    const handleReadSelected = () => {
        dispatch(markNotificationsRead(selected))
        setTimeout(() => setSelected([]))
    }

    const handleReadAll = () => {
        dispatch(markNotificationsRead(unreadNotificationIds))
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSelected((prev) => [...prev, e.target.id])
        } else {
            setSelected((prev) => prev.filter((id) => id !== e.target.id))
        }
    }

    const handleLoadMore = () => {
        dispatch(
            fetchNotificationsOnScroll(notifications.at(-1)?.notification_id)
        )
    }

    return (
        <Modal
            show
            scrollable
            onHide={handleClose}
            dialogClassName={style.customModal}
            backdropClassName={style.customBackdrop}
        >
            <Modal.Header className={style.header}>
                <Modal.Title>
                    Notifications &nbsp;
                    <span className={style.notisCount}>
                        {unreadNotificationIds.length}
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={style.customBody}>
                {!!unreadNotificationIds.length && (
                    <div className={style.controls}>
                        <button
                            className={`btn-clean ${style.controlBtn}`}
                            onClick={handleReadAll}
                        >
                            <FontAwesomeIcon icon={faCheck} />
                            <span>Mark all as read</span>
                        </button>
                        <button
                            className={`btn-clean ${style.controlBtn}`}
                            onClick={handleReadSelected}
                            disabled={!selected.length}
                        >
                            <FontAwesomeIcon icon={faCheck} />
                            <span>Mark selected as read</span>
                        </button>
                    </div>
                )}
                <div className={style.notisContainer}>
                    {!!notifications.length &&
                        notifications.map((notis) => (
                            <Notification
                                key={notis.notification_id}
                                notification={notis}
                                handleChange={handleChange}
                            />
                        ))}
                    <button
                        onClick={handleLoadMore}
                        className={`${style.more} m-auto`}
                    >
                        <FormattedMessage id="buttons.moreNotifications" />
                        <span className="ms-2">
                            <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
