import { Periods } from '../types'

export const getMinRange = (date: Date): Date => new Date(date)

export const getMaxRange = (date: Date): Date | undefined => {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() + 13)
    return newDate
}

export const getValidEndDate = (dateFrom: Date, dateTo: Date): Date => {
    const newDateFrom = new Date(dateFrom)
    const actualDateTo = new Date(dateTo)
    const today = new Date()

    const twoWeeksFromNow = new Date(
        newDateFrom.setDate(newDateFrom.getDate() + 13)
    )

    const potentialDate =
        twoWeeksFromNow.getTime() < today.getTime() ? twoWeeksFromNow : today

    const newDateTo =
        actualDateTo.getTime() < potentialDate.getTime() &&
        actualDateTo.getTime() >= dateFrom.getTime()
            ? actualDateTo
            : potentialDate

    return newDateTo
}

export const periodOptions = [
    { label: 'Custom', value: Periods.CUSTOM },
    { label: 'Today', value: Periods.TODAY },
    { label: 'Last 3 days', value: Periods.THREE_DAYS },
    { label: 'Last week', value: Periods.WEEK },
    { label: 'Last 2 weeks', value: Periods.TWO_WEEKS },
]

export enum LogsStatus {
    ACCEPTED = 'Accepted',
    EXECUTED = 'Executed',
    DECLINED = 'Declined',
}

export const getRange = (state: any, setLogsState: any) => {
    const date = new Date()

    switch (state?.period?.value) {
        case Periods.TODAY:
            setLogsState({
                ...state,
                from: new Date(),
                to: new Date(),
            })
            break

        case Periods.THREE_DAYS:
            setLogsState({
                ...state,
                from: new Date(date?.setDate(date?.getDate() - 2)),
                to: new Date(),
            })
            break

        case Periods.WEEK:
            setLogsState({
                ...state,
                from: new Date(date?.setDate(date?.getDate() - 6)),
                to: new Date(),
            })
            break

        case Periods.TWO_WEEKS:
            setLogsState({
                ...state,
                from: new Date(date?.setDate(date?.getDate() - 13)),
                to: new Date(),
            })
            break

        default:
            setLogsState({ ...state })
            break
    }
}

export interface IPeriod {
    from: string
    to: string
}

export class Period {
    period: IPeriod

    constructor(from: string, to: string) {
        this.period = { from: from, to: to }
    }
}

export const convertDatesToSendFormat = (dates: {
    from: Date
    to: Date
}): Period => {
    const from = dates.from?.toISOString().slice(0, 10)
    const to = dates.to?.toISOString().slice(0, 10)

    return new Period(from, to)
}

