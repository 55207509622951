import React, { FC, useEffect, useMemo, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import style from '../Styles/index.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getLabel } from '../../../../../validators'
import PromptBtn from '../../../../buttons/PromptBtn/PromptBtn'
import { initEmptyData } from '../../../../../store/actions/modelsActions/control'
import { componentConstructor } from '../../../../GUIConstructor/control'
import { ConfigurationsActionType } from '../../../../../types/configurations'
import CheckboxInput from '../../../../inputs/CheckboxInput'
import { IBlockModelProps } from '../types'
import { useAppDispatch } from '../../../../../hooks/useAppDispatch'
import { setInitialShow } from '../../Utils'
import { checkUnsavedChanges } from '../../../../../store/actions/configurationActions'

const MailSender: FC<IBlockModelProps> = ({
    data = {},
    dataSchema = {},
    description,
    wrapper,
    dataPath,
    name,
    isParentDisabled,
    isViewOnly,
}) => {
    const { is_required: isRequired, is_auxiliary: isCollapsed } = dataSchema
    const dispatch = useAppDispatch()

    const [path] = useState<string[]>([...(dataPath || []), name])
    const [dataProp, setDataProp] = useState<any>(data)
    const [blockState, setBlockState] = useState<any>({
        [name]: !!data,
    })
    const [childrenPath, setChildrenPath] = useState<string[]>(
        blockState[name] ? path : []
    )

    const [show, setShow] = useState(!isCollapsed)
    const localId = `${path}_${name}`

    const id: string = String(+new Date())
    const styleHeader: string[] = [
        style.header,
        'app-color-light rounded w-100 border d-flex align-items-center justify-content-between pointer',
    ]

    const handleShow = () => {
        setShow((prev) => {
            localStorage.setItem(localId, JSON.stringify(!prev))
            return !prev
        })
    }

    const onChecked = (): void => {
        dispatch({
            type: ConfigurationsActionType.ENABLE_BLOCK,
            payload: { path, name, data: dataProp },
        })
    }

    useEffect(() => {
        if (blockState[name] && !isParentDisabled) {
            onChecked()
        }
    }, [blockState[name]]) // eslint-disable-line

    useEffect(() => {
        if (!blockState[name] || isParentDisabled) {
            setChildrenPath([])
        }
        if (blockState[name] && !isParentDisabled) {
            setChildrenPath(path)
        }
        if (!blockState[name]) {
            dispatch({
                type: ConfigurationsActionType.DISABLE_BLOCK,
                payload: { path, name },
            })
            requestIdleCallback(() => dispatch(checkUnsavedChanges(path[0])))
        }
    }, [blockState[name], isParentDisabled]) // eslint-disable-line

    useMemo(() => {
        if (!Object.keys(data || {}).length || data === null) {
            initEmptyData(name, path, dataSchema)
            setDataProp({})
        }
        setInitialShow(localId, isCollapsed, setShow)
    }, []) // eslint-disable-line

    return (
        <div
            style={{ maxWidth: '600px' }}
            className={`${isViewOnly ? 'viewOnly' : ''} ${
                !wrapper ? 'w-50 flex-item' : 'border w-100'
            } mw-350  d-flex flex-column rounded mt-2 mb-2 position-relative`}
        >
            <div
                className={styleHeader.join(' ')}
                onClick={handleShow}
            >
                <div
                    className="w-100"
                    onClick={handleShow}
                >
                    <h4 className={style.title}>
                        {dataSchema?.name || getLabel(name)}
                    </h4>{' '}
                </div>
                {description && (
                    <PromptBtn
                        className="ms-auto me-2 text-white"
                        size="small"
                        prompt={description}
                    />
                )}
                {!isRequired && (
                    <CheckboxInput
                        state={blockState}
                        name={name}
                        label=""
                        setState={setBlockState}
                        checkboxType="switch-md"
                    />
                )}
                <button
                    className={`${style.button} alwaysActive`}
                    aria-controls={id}
                    aria-expanded={show}
                >
                    <FontAwesomeIcon icon={show ? faAngleUp : faAngleDown} />
                </button>
            </div>
            <Collapse in={show}>
                <div className="p-relative">
                    {!blockState[name] && <div className="block-overlay" />}
                    <div
                        className="app-bg-primary p-2"
                        id={id}
                    >
                        <div className={`d-flex w-100 flex-column`}>
                            {componentConstructor(
                                dataSchema,
                                dataProp || {},
                                name || '',
                                'block',
                                '',
                                childrenPath
                            )}
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    )
}

export default MailSender
