import { ProductStatus, ProductType } from '../entity/Product'

export enum ProductActionType {
    FETCH_PRODUCTS = 'FETCH_PRODUCTS',
    FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS',
    FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR',
    FETCH_PRODUCTS_CANCEL = 'FETCH_PRODUCTS_CANCEL',
    DELETE_PRODUCT = 'DELETE_PRODUCT',
    DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS',
    DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR',
    SET_PRODUCT_ROUTED = 'SET_PRODUCT_ROUTED',
    RENAME_PRODUCT = 'RENAME_PRODUCT',
    RENAME_PRODUCT_SUCCESS = 'RENAME_PRODUCT_SUCCESS',
    RENAME_PRODUCT_ERROR = 'RENAME_PRODUCT_ERROR',
    FETCH_QUICK_ACCESS = 'FETCH_QUICK_ACCESS',
    QUICK_ACCESS_ADD_ITEM = 'QUICK_ACCESS_ADD_ITEM',
    QUICK_ACCESS_REMOVE_ITEM = 'QUICK_ACCESS_REMOVE_ITEM',
    QUICK_ACCESS_DND = 'QUICK_ACCESS_DND',
    FETCH_PRODUCTS_GROUPS = 'FETCH_PRODUCTS_GROUPS',
    RENAME_PRODUCT_GROUP_SUCCESS = 'RENAME_PRODUCT_GROUP_SUCCESS',
    RENAME_PRODUCT_GROUP_ERROR = 'RENAME_PRODUCT_GROUP_ERROR',
    RENAME_PRODUCT_GROUP = 'RENAME_PRODUCT_GROUP',
    REORDER_PRODUCTS_GROUPS = 'REORDER_PRODUCTS_GROUPS',
    PRODUCT_DND_INSIDE_GROUP = 'PRODUCT_DND_INSIDE_GROUP',
    PRODUCT_DND_BETWEEN_GROUPS = 'PRODUCT_DND_BETWEEN_GROUPS',
    CHANGE_PRODUCT_STATUS = 'CHANGE_PRODUCT_STATUS',
}

export interface IProductsReducer {
  products: IProduct[]
  quickAccess: string[]
  productsGroups: IProductGroup[]
  isLoading: boolean
  error: any
  isProductRouted: boolean
  productsIdDispNameDict: Record<string, string>
  productLoading?: string
}

export interface IProduct {
  status: ProductStatus
  guid: string
  name: string
  version: string
  path: string
  ip: string
  display_name?: string
  type?: ProductType
}

export interface IProductGroup {
  group_id: string
  type: string
  name: string
  entries: string[]
  position: number | string
}
