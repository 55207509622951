import React from 'react'
import DateTime from '../models/DateTime/DateTime'
import DBConnection from '../models/Block/BlockModals/DBConnection/DBConnection'
import Double from '../models/Double/Double'
import Duration from '../models/Duration/Duration'
import Enum from '../models/Enum/Enum'
import Flag from '../models/Flag/Flag'
import Integer from '../models/Integer/Integer'
import MailSender from '../models/Block/BlockModals/MailSender/MailSender'
import MtConnection from '../models/Block/BlockModals/MtConnection/MtConnection'
import Secret from '../models/Secret/Secret'
import StringModel from '../models/StringInput/StringModel'
import { ModelTypes } from '../models/types'
import { InputViewOnly } from '../ViewOnly/InputViewOnly'
import { getIsViewOnly } from '../../utils/viewOnly'
import { IProduct } from '../../types/product'
import { Text } from '../models/Text/Text'

const Block = React.lazy(() => import('../models/Block/Block'))
const BlockArray = React.lazy(() => import('../models/BlockArray/BlockArray'))
const BlockDictionary = React.lazy(
  () => import('../models/BlockDictionary/BlockDictionary')
)
const WrappedBlock = React.lazy(() => import('../models/Block/WrappedBlock'))

interface IElements {
  [key: string]: JSX.Element
}

export function buildElementsExt(
  name: string,
  ProductScheme: any = {},
  ProductConfiguration: any = {},
  wrapper?: string,
  path?: any,
  isParentDisabled?: boolean,
  levelDeep?: number,
  element_id?: string,
  inCard?: boolean,
  isRuleActive?: boolean
): JSX.Element {
  const elementType: string = ProductScheme?.type || 'default'
  const isViewOnly = getIsViewOnly(ProductScheme.is_access_restricted)
  const isGeneratedByProduct = ProductScheme.is_generated_by_product

  if (isGeneratedByProduct) return <></>

  const elements: IElements = {
    [ModelTypes.MT_CONNECTION]: (
      <MtConnection
        title={ProductScheme?.name}
        data={ProductConfiguration?.[name]}
        name={name}
        dataSchema={ProductScheme}
        wrapper={wrapper || ''}
        dataPath={path}
        isParentDisabled={isParentDisabled}
        key={name + path}
        isViewOnly={isViewOnly}
      />
    ),
    [ModelTypes.DB_CONNECTION]: (
      <DBConnection
        title={ProductScheme?.name}
        data={ProductConfiguration?.[name]}
        dataSchema={ProductScheme}
        name={name}
        wrapper={wrapper || ''}
        dataPath={path}
        isParentDisabled={isParentDisabled}
        key={name + path}
        isViewOnly={isViewOnly}
      />
    ),
    [ModelTypes.MAIL_SENDER]: (
      <MailSender
        title={ProductScheme?.name}
        data={ProductConfiguration?.[name]}
        dataSchema={ProductScheme}
        name={name}
        wrapper={wrapper || ''}
        dataPath={path}
        isParentDisabled={isParentDisabled}
        key={name + path}
        isViewOnly={isViewOnly}
      />
    ),
    [ModelTypes.BLOCK]: !wrapper ? (
      <Block
        title={ProductScheme?.name}
        data={ProductConfiguration?.[name]}
        dataSchema={ProductScheme}
        name={name}
        wrapper={wrapper || ''}
        dataPath={path}
        isParentDisabled={isParentDisabled}
        key={name + path}
        isViewOnly={isViewOnly}
        element_id={element_id}
        inCard={inCard}
        isRuleActive={isRuleActive}
      />
    ) : (
      <WrappedBlock
        title={ProductScheme?.name}
        data={ProductConfiguration?.[name]}
        dataSchema={ProductScheme}
        name={name}
        wrapper={wrapper || 'block'}
        dataPath={path}
        isParentDisabled={isParentDisabled}
        levelDeep={levelDeep}
        key={name + path}
        isViewOnly={isViewOnly}
        element_id={element_id}
        inCard={inCard}
        isRuleActive={isRuleActive}
      />
    ),
    [ModelTypes.BLOCK_ARRAY]: (
      <BlockArray
        config={ProductConfiguration?.[name]}
        schema={ProductScheme}
        title={name}
        dataPath={path}
        wrapper={wrapper}
        key={name + path}
        levelDeep={levelDeep}
        isViewOnly={isViewOnly}
        element_id={element_id}
        inCard={inCard}
        isRuleActive={isRuleActive}
      />
    ),
    [ModelTypes.BLOCK_DICTIONARY]: (
      <BlockDictionary
        wrapper={wrapper}
        dataSchema={ProductScheme}
        name={name}
        data={ProductConfiguration?.[name]}
        dataPath={path}
        isViewOnly={isViewOnly}
        element_id={element_id}
        inCard={inCard}
        isRuleActive={isRuleActive}
      />
    ),
    [ModelTypes.FLAG]: (
      <Flag
        data={ProductConfiguration}
        dataSchema={ProductScheme}
        name={name}
        dataPath={path}
        isViewOnly={isViewOnly}
        key={name + path}
      />
    ),
    [ModelTypes.BOOL]: (
      <Flag
        data={ProductConfiguration}
        dataSchema={ProductScheme}
        name={name}
        dataPath={path}
        isViewOnly={isViewOnly}
        key={name + path}
      />
    ),
    [ModelTypes.ENUM]: isViewOnly ? (
      <InputViewOnly
        name={name}
        title={ProductScheme.name}
        value={ProductConfiguration?.[name]}
        key={name + path}
      />
    ) : (
      <Enum
        data={ProductConfiguration}
        dataSchema={ProductScheme}
        name={name}
        wrapper={wrapper || ''}
        dataPath={path}
        key={name + path}
      />
    ),
    [ModelTypes.INTEGER]: isViewOnly ? (
      <InputViewOnly
        name={name}
        title={ProductScheme.name}
        value={ProductConfiguration?.[name]}
        key={name + path}
      />
    ) : (
      <Integer
        dataSchema={ProductScheme}
        data={ProductConfiguration}
        name={name}
        dataPath={path}
        wrapper={wrapper || ''}
        key={name + path}
      />
    ),
    [ModelTypes.NUMBER]: isViewOnly ? (
      <InputViewOnly
        name={name}
        title={ProductScheme.name}
        value={ProductConfiguration?.[name]}
        key={name + path}
      />
    ) : (
        <Double
            dataSchema={ProductScheme}
            data={ProductConfiguration}
            name={name}
            wrapper={wrapper || ''}
            dataPath={path}
            key={name + path}
        />
      /* <Num
        componentSchema={ProductScheme}
        componentConfig={ProductConfiguration}
        componentName={name}
        path={path}
        wrapper={wrapper || ''}
        key={name + path}
      /> */
    ),
    [ModelTypes.STRING]: isViewOnly ? (
      <InputViewOnly
        name={name}
        title={ProductScheme.name}
        value={ProductConfiguration?.[name]}
        key={name + path}
      />
    ) : (
      <StringModel
        dataSchema={ProductScheme}
        data={ProductConfiguration}
        name={name}
        wrapper={wrapper || ''}
        dataPath={path}
        key={name + path}
      />
    ),
    [ModelTypes.TEXT]: isViewOnly ? (
      <InputViewOnly
        name={name}
        title={ProductScheme.name}
        value={ProductConfiguration?.[name]}
        key={name + path}
      />
    ) : (
      <Text
        dataSchema={ProductScheme}
        data={ProductConfiguration}
        name={name}
        wrapper={wrapper || ''}
        dataPath={path}
        key={name + path}
      />
    ),
    [ModelTypes.VERSION]: isViewOnly ? (
      <InputViewOnly
        name={name}
        title={ProductScheme.name}
        value={ProductConfiguration?.[name]}
        key={name + path}
      />
    ) : (
      <StringModel
        dataSchema={ProductScheme}
        data={ProductConfiguration}
        name={name}
        wrapper={wrapper || ''}
        dataPath={path}
        key={name + path}
      />
    ),
    [ModelTypes.SECRET]: isViewOnly ? (
      <InputViewOnly
        type={ModelTypes.SECRET}
        name={name}
        title={ProductScheme.name}
        value={ProductConfiguration?.[name]}
        key={name + path}
      />
    ) : (
      <Secret
        dataSchema={ProductScheme}
        data={ProductConfiguration}
        name={name}
        wrapper={wrapper || ''}
        dataPath={path}
        key={name + path}
      />
    ),
    [ModelTypes.DATETIME]: isViewOnly ? (
      <InputViewOnly
        name={name}
        title={ProductScheme.name}
        value={ProductConfiguration?.[name]}
        key={name + path}
      />
    ) : (
      <DateTime
        dataSchema={ProductScheme}
        data={ProductConfiguration}
        name={name}
        dataPath={path}
        key={name + path}
        isParentDisabled={isParentDisabled}
      />
    ),
    [ModelTypes.TIME]: isViewOnly ? (
      <InputViewOnly
        name={name}
        title={ProductScheme.name}
        value={ProductConfiguration?.[name]}
        key={name + path}
      />
    ) : (
        <DateTime
            dataSchema={ProductScheme}
            data={ProductConfiguration}
            name={name}
            dataPath={path}
            key={name + path}
            isParentDisabled={isParentDisabled}
        />
    ),
    [ModelTypes.DOUBLE]: isViewOnly ? (
      <InputViewOnly
        name={name}
        title={ProductScheme.name}
        value={ProductConfiguration?.[name]}
        key={name + path}
      />
    ) : (
      <Double
        dataSchema={ProductScheme}
        data={ProductConfiguration}
        name={name}
        wrapper={wrapper || ''}
        dataPath={path}
        key={name + path}
      />
    ),
    [ModelTypes.DURATION]: isViewOnly ? (
      <InputViewOnly
        name={name}
        title={ProductScheme.name}
        value={ProductConfiguration?.[name]}
        key={name + path}
      />
    ) : (
      <Duration
        dataSchema={ProductScheme}
        data={ProductConfiguration}
        name={name}
        wrapper={wrapper || ''}
        dataPath={path}
        key={name + path}
      />
    ),
    default: <></>,
  }

  return elements[elementType]
}

export const componentConstructor = (
  ProductScheme: any = {},
  ProductConfiguration: any = {},
  name: string,
  wrapper?: string,
  classname?: string,
  path?: string[],
  elements?: any,
  isParentDisabled?: boolean,
  levelDeep?: number,
  element_id?: string,
  inCard?: boolean,
  isRuleActive?: boolean
): JSX.Element => {
  let elementsGUI = elements || {}

  if (ProductScheme.children) {
    const keys = Object.keys(ProductScheme.children)
    for (const item in keys) {
      const element = buildElementsExt(
        keys[item],
        ProductScheme.children[keys[item]],
        ProductConfiguration,
        wrapper,
        path,
        isParentDisabled,
        levelDeep,
        element_id,
        inCard,
        isRuleActive
      )
      elementsGUI = {
        ...elementsGUI,
        [name]: { ...elementsGUI[name], [keys[item]]: [element] },
      }
    }
  }

  const keys = elementsGUI[name] ? Object.keys(elementsGUI[name]) : []
  return (
    <div className={`${classname}`}>
      {keys.map((el: string) => elementsGUI[name][el])}
    </div>
  )
}

export const routeConstructor = (
  ProductScheme: any = {},
  ProductConfiguration: any = {},
  name: string,
  guid: string,
  product?: IProduct
): JSX.Element => {
  const path: string[] = [guid, 'configuration', name]

  return (
    <div className="pb-1 pt-2">
      {product?.path && (
        <p className="w-100 d-flex flex-item pt-1 pb-1 border-bottom mb-1 justify-content-between">
          <span className="inline-block">
            <span style={{ fontSize: '18px' }} className="app-color-text h5">
              Path: &nbsp;
            </span>
            <span className="text-secondary">{product.path}</span>
          </span>
          {product?.ip && (
            <span className="inline-block" style={{ textAlign: 'end'}}>
              <span style={{ fontSize: '18px' }} className="inline-block app-color-text h5">
                Product IP: &nbsp;
              </span>
              <span className="text-secondary">{product.ip}</span>
            </span>
          )}
        </p>
      )}
      {componentConstructor(
        ProductScheme[name],
        ProductConfiguration[name],
        name,
        '',
        'd-flex flex-wrap min-500 flex-container',
        path
      )}
    </div>
  )
}
