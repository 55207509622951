import { Modal } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import styles from './LogsPage.module.scss'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { IProduct } from '../../types/product'
import { convertToSentenceCase } from '../../utils/convertToSentenceCase'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchProductLiveLogs } from '../../store/actions/logsActions'
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { fuzzyFilter } from '../../utils/fuzzyFilter'
import { LEVELS } from '../../types/logs'

const userLocale = navigator.language || 'en-US'
const emptyArr: any = []

export const LogsPage = () => {
    const dispatch = useDispatch()
    const { productId } = useParams<{ productId: string }>()
    const { products } = useTypedSelector((store) => store.products)
    const curProduct = products.find(
        (product: IProduct) => product.guid === productId
    )
    const { productsLiveLogs } = useTypedSelector((store) => store.logs)
    const productLiveLogs = productsLiveLogs[productId]
    const [sorting, setSorting] = useState([])

    const columns = useMemo<ColumnDef<unknown>[]>(
        () => [
            {
                accessorKey: 'log_time',
                cell: (info) => {
                    if(info.getValue()) {
                        return new Date(info.getValue() as string).toLocaleString(userLocale)
                    }
                },
                header: () => 'TIME',
            },
            {
                accessorKey: 'level',
                cell: (info) => LEVELS[info.getValue() as keyof typeof LEVELS],
                header: () => 'LEVEL',
            },
            {
                accessorKey: 'tag',
                cell: (info) => info.getValue(),
                header: () => 'TAG',
            },
            {
                accessorKey: 'message_template',
                cell: (info) => info.getValue(),
                header: () => 'MESSAGE',
            },
        ],
        []
    )

    const table = useReactTable({
        data: productLiveLogs ?? emptyArr,
        columns: columns,
        state: { sorting },
        filterFns: { fuzzy: fuzzyFilter },
        // @ts-ignore
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    })

    useEffect(() => {
        const abortController = new AbortController()
        dispatch(fetchProductLiveLogs(productId, abortController.signal))

        return () => abortController.abort()
    }, [dispatch, productId])

    return (
        <Modal
            show
            fullscreen
            keyboard={false}
        >
            <Modal.Body className={`app-bg-primary ${styles.container}`}>
                <h4>Live logs</h4>
                Product:{' '}
                <span className={styles.pName}>
                    {curProduct ? convertToSentenceCase(curProduct.name) : ''}
                </span>
                {
                    <table className={styles.table}>
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => {
                                        return (
                                            <th
                                                key={header.id}
                                                colSpan={header.colSpan}
                                                className={styles.tableHead}
                                            >
                                                {header.isPlaceholder ? null : (
                                                    <div
                                                        {...{
                                                            className:
                                                                header.column.getCanSort()
                                                                    ? styles.sortable
                                                                    : '',
                                                            onClick:
                                                                header.column.getToggleSortingHandler(),
                                                        }}
                                                    >
                                                        {flexRender(
                                                            header.column
                                                                .columnDef
                                                                .header,
                                                            header.getContext()
                                                        )}
                                                        {{
                                                            asc: ' 🔼',
                                                            desc: ' 🔽',
                                                        }[
                                                            header.column.getIsSorted() as string
                                                        ] ?? null}
                                                    </div>
                                                )}
                                            </th>
                                        )
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row) => {
                                // @ts-ignore
                                const lvl = row.original.level
                                return (
                                    <tr key={row.id} className={`${styles.tableRow} ${styles[lvl]}`}>
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                <td
                                                    key={cell.id}
                                                    className={styles.tableCell}
                                                >
                                                    <div className={styles.cellContent}>
                                                        {flexRender(
                                                            cell.column.columnDef
                                                                .cell,
                                                            cell.getContext()
                                                        )}
                                                    </div>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                }
            </Modal.Body>
        </Modal>
    )
}
