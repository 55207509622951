import { IStringInputSchema } from '../types'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { inputValidationCheck } from '../../../validators/inputValidators/inputValidators'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { getLabel } from '../../../validators'
import { stringInputErrors } from '../../../utils/validationErrors/stringInputErrors'
import React, { useEffect, useState } from 'react'
import { initInputData } from '../../../utils/InitInputData'
import { inputDataDispatcher } from '../../../utils/InputDataDispatcher'
import { buildControlsExt, textInput } from '../../inputs/controls'

interface TextProps {
    data: any
    dataSchema: IStringInputSchema
    name: string
    wrapper?: string
    dataPath?: string[]
}

export const Text = (props: TextProps) => {
    const { data, dataSchema, name, wrapper, dataPath } = props

    const dispatch = useDispatch()
    const path = [...(dataPath || []), name]
    const [checkPath, setCheckPath] = useState<string[]>([])

    const { isChecked } = useTypedSelector((state) => state.configurations)

    const {
        is_required: isRequired,
        name: inputLabel,
        type,
        description: tooltipText,
        is_email: isEmail,
        default: defaultValue,
    } = dataSchema

    const validationCheck = inputValidationCheck(type, dataSchema)
    const [
        inputState,
        setInputState,
        touched,
        setTouched,
        validationResult,
        isValid,
    ] = useFormValidation(data || {}, { [name]: validationCheck })
    const guid = path[0]
    const label = inputLabel || getLabel(name)
    const className = !wrapper ? 'w-50' : ''
    const errorKey = path.join('')
    const isUndefined: boolean = typeof data?.[name] === 'undefined'
    const isDataEmpty: number = Object.keys(data || {}).length
    const isEmpty: boolean = !data || !isDataEmpty || isUndefined
    const errMsg = stringInputErrors(inputState?.[name], dataSchema)

    useEffect(() => {
        if (dataPath?.length) {
            setCheckPath(dataPath)
            initInputData(
                {
                    isEmpty,
                    name,
                    path,
                    dataSchema,
                    isRequired,
                    guid,
                    errorKey,
                    data,
                    setInputState,
                },
                dispatch
            )
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        if (isChecked[guid] && dataPath?.length) {
            isValid()
        }
    }, [isChecked, dataPath?.length]) // eslint-disable-line

    useEffect(() => {
        if (data && dataPath?.length) {
            setInputState(data)
        }
    }, [data, dataPath?.length]) // eslint-disable-line

    useEffect(() => {
        if (dataPath?.length) {
            inputDataDispatcher(
                {
                    isEmpty,
                    name,
                    path,
                    dataSchema,
                    data,
                    inputState,
                    validationCheck,
                    guid,
                    errorKey,
                    isUndefined,
                    checkPath,
                    type,
                },
                dispatch
            )
        }
    }, [inputState[name], touched[name], isChecked[guid]]) // eslint-disable-line

    const textArea = buildControlsExt(
        [
            textInput({
                name,
                tooltipText,
                label,
                className,
                isColumn: true,
                errMsg,
                isRequired,
                isEmail,
                placeholder: defaultValue,
            }),
        ],
        inputState,
        setInputState,
        '',
        touched,
        setTouched,
        validationResult
    )

    return (
        <div className={`${!wrapper ? 'w-100' : ''}`}>{textArea}</div>
    )
}
