import style from '../NotificationsModal.module.scss'
import { INotification } from '../../../../types/notifications'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { checkOverflow } from '../../../../utils/checkOverflow'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown'
import { useTypedSelector } from '../../../../hooks/useTypedSelector'

interface NotificationProps {
    className?: string
    notification: INotification
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const userLocale = navigator.language || 'en-US'

export const Notification = (props: NotificationProps) => {
    const { className, notification, handleChange } = props
    const [viewMore, setViewMore] = useState(false)
    const [showViewMore, setShowViewMore] = useState(false)
    const notificationRef = useRef<HTMLSpanElement>(null)
    const { productsIdDispNameDict } = useTypedSelector(
        (store) => store.products
    )

    const handleViewMore = () => {
        setViewMore((prev) => !prev)
    }

    useEffect(() => {
        if (notificationRef.current) {
            const hasOverflowItems = checkOverflow(notificationRef.current)
            const isOverMH = notificationRef.current?.scrollHeight > 45
            setShowViewMore(hasOverflowItems || isOverMH)
        }
    }, [])

    return (
        <span
            key={notification.notification_id}
            className={`${style.notis}`}
        >
            {<input
                id={notification.notification_id}
                className={style.checkbox}
                onChange={handleChange}
                type="checkbox"
                disabled={notification.status === 'read'}
            />}
            <span className={`${style.notisContent}`}>
                <span className={style.notisHead}>
                    <span className={style.notisTime}>
                        {new Date(notification.created_at).toLocaleString(
                            userLocale
                        )}
                    </span>
                    {notification.status === 'unread' && (
                        <span className={style.new}>New</span>
                    )}
                </span>
                <span
                    ref={notificationRef}
                    className={`${style.notisText} ${
                        notification.status === 'read' && style.read
                    } ${viewMore ? '' : style.notisMaxHeight}`}
                >
                    <span className={style.semiB}>
                        {`${productsIdDispNameDict[notification.product_id]}:`}
                    </span>{' '}
                    &nbsp;
                    {notification.message}
                </span>

                {showViewMore && (
                    <button
                        onClick={handleViewMore}
                        className={`${style.more}`}
                    >
                        {viewMore ? (
                            <>
                                <FormattedMessage id="buttons.hide" />
                                <span className="ms-2">
                                    <FontAwesomeIcon icon={faAngleUp} />
                                </span>
                            </>
                        ) : (
                            <>
                                <FormattedMessage id="buttons.more" />
                                <span className="ms-2">
                                    <FontAwesomeIcon icon={faAngleDown} />
                                </span>
                            </>
                        )}
                    </button>
                )}
            </span>
        </span>
    )
}
